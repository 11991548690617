import { render, staticRenderFns } from "./SubmissionForm.vue?vue&type=template&id=45f24e96&scoped=true&lang=pug&"
import script from "./SubmissionForm.vue?vue&type=script&lang=js&"
export * from "./SubmissionForm.vue?vue&type=script&lang=js&"
import style0 from "@platyplus/humanitarian-icons/dist/icons.css?vue&type=style&index=0&lang=css&"
import style1 from "./SubmissionForm.vue?vue&type=style&index=1&id=45f24e96&lang=scss&scoped=true&"
import style2 from "./SubmissionForm.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f24e96",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QField from 'quasar/src/components/field/QField.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QBreadcrumbs,QIcon,QBreadcrumbsEl,QSelect,QCheckbox,QField,QOptionGroup,QInput,QChip,QItem,QItemSection,QItemLabel,QBtn,QLinearProgress});
